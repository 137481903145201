import React from 'react';

import BaseLayout from '../components/layouts/BaseLayout';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';
import { Section } from '../components/layouts/Containers';
import { List, ListItem } from '../components/elements/Lists';
import { Link } from '../components/elements/ButtonsAndLinks';
import {
    downloadSjfpAnalogesFormular2020,
    downloadsSjfpLeitfadenBewerbungsformular,
    downloadSjfpPressemeldung,
    downloadSjfpFlyer2020,
    downloadSjfp2020Preistraeger,
    downloadSjfp2020Gewinner,
    downloadSjfp2020PressemeldungGewinner,
} from '../helpers/downloads';
import { Headline } from '../components/elements/Typography';

const DownloadsPage = () => (
    <BaseLayout
        title="Downloads"
        description="Unterlagen des LOTTO Sportjugend-Förderpreises herunterladen"
    >
        <Stage>
            <StageIntro
                headline={{ text: 'Downloads', smaller: true }}
                copy="Hier können Sie diverse Unterlagen zur aktuellen und vergangenen Ausschreibung herunterladen."
            />
        </Stage>
        <Section container="m">
            <Headline level="h3">Sportjugend-Förderpreis 2020</Headline>
            <List
                gap={{
                    small: 'l',
                    medium: 'xl',
                    large: 'xxl',
                }}
            >
                <ListItem hasBorder type="plain">
                    <Link type="download" to="/downloads/sjfp-animation.zip" target="_blank">
                        Download Imagevideo Sportjugend-Förderpreis 2020
                    </Link>
                </ListItem>
                <ListItem hasBorder type="plain">
                    <Link type="download" to={downloadSjfp2020Preistraeger}>
                        Download Preisträger Sportjugend-Förderpreis 2020
                    </Link>
                </ListItem>
                <ListItem hasBorder type="plain">
                    <Link type="download" to={downloadSjfp2020Gewinner}>
                        Download Gewinner Sportjugend-Förderpreis 2020
                    </Link>
                </ListItem>
                <ListItem hasBorder type="plain">
                    <Link type="download" to={downloadSjfp2020PressemeldungGewinner}>
                        Download Pressemeldung Sportjugend-Förderpreis 2020 Gewinner
                    </Link>
                </ListItem>
                <ListItem hasBorder type="plain">
                    <Link type="download" to={downloadSjfpFlyer2020}>
                        Download Flyer Sportjugend-Förderpreis 2020
                    </Link>
                </ListItem>
                <ListItem hasBorder type="plain">
                    <Link type="download" to={downloadSjfpAnalogesFormular2020}>
                        Download Bewerbungsformular Sportjugend-Förderpreis 2020
                    </Link>
                </ListItem>
                <ListItem hasBorder type="plain">
                    <Link type="download" to={downloadSjfpPressemeldung}>
                        Download Pressemeldung Sportjugend-Förderpreis 2020
                    </Link>
                </ListItem>
            </List>

            <Headline level="h3">Allgemeine Downloads</Headline>
            <List gap="none">
                <ListItem hasBorder type="plain">
                    <Link
                        type="link"
                        to="https://lotto-bw.de/datenschutz"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Datenschutz
                    </Link>
                </ListItem>
                <ListItem hasBorder type="plain">
                    <Link
                        type="download"
                        to={downloadsSjfpLeitfadenBewerbungsformular}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Download Leitfaden Bewerbungsformular
                    </Link>
                </ListItem>
            </List>
        </Section>
    </BaseLayout>
);

export default DownloadsPage;
